import {useEffect} from 'react';

import Loading from 'components/Loading';
import useSearchBar from 'components/Search/Bar/Provider';
import SideNav from 'components/SideNav';
import {getMenuItems, getTabComponent} from 'components/SideNav/utils';
import KyError from 'components/errors/KyError';
import NotFound from 'components/shared/error/not_found';
import FullWidthResourceTemplate from 'components/templates/FullWidthResource';
import useAppContext from 'conf/AppContext';
import Types from 'conf/types';
import {useGet} from 'helpers/KyHooks';
import AlleleOverviewTab from 'pages/Allele/OverviewTab';

export default function AllelePage({id, section = 'overview', loadId = 0}) {
  const appContext = useAppContext();
  const searchBar = useSearchBar();
  const showOverview = !section || section === 'overview';
  
  const {response, error} = useGet(`data/allele/${id}`, {
    cacheResponse: !appContext.isPreview, searchParams: {view: 'most'},
  }, {
    loadId,
  });
  useEffect(() => {
    if (response) {
      searchBar.initializeSearchBar([{id, objCls: 'allele', name: response.data.name}]);
    }
  }, [response]);

  let name = `Allele ${id}`;
  let body = <Loading />;
  let counts = {};
  if (error) {
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    body = <KyError kyError={error} />;
  } else if (response) {
    counts = response.data.counts || {};
    const allele = response.data;
    name = allele.name;
    if (showOverview) {
      body = (
        <AlleleOverviewTab allele={allele} />
      );
    } else {
      const Comp = getTabComponent(section);
      body = <Comp id={id} objCls="allele" name={name} />;
    }
  }

  const baseUrl = Types.allele.url(id);
  const menuItems = getMenuItems('allele', baseUrl, counts);
  return (
    <FullWidthResourceTemplate type="allele" id={id} name={name}>
      <SideNav items={menuItems}>
        {body}
      </SideNav>
    </FullWidthResourceTemplate>
  );
}
