import {isArray, join, map} from 'lodash';
import {matchSorter} from 'match-sorter';
import PropTypes from 'prop-types';

import AccIdCell from 'components/Table/Cell/AccId';
import TextFilter from 'components/Table/Filter/Text';


/**
 * Typical defaults for a VariantLocation column.
 */
export const baseVariantLocationColumn = {
  Cell: VariantLocationCell,
  sortable: true,
  sortType: sortVariantLocations,
  Filter: TextFilter,
  filter: variantLocationFilter,
  getCellExportValue: variantLocationExportValue,
};


const propTypes = {
  value: PropTypes.object,
};
/**
 * Renders a table cell containing VariantLocation(s).
 *
 * To filter, use `{Filter: TextFilter, filter: variantLocationFilter}`.
 *
 * To sort, use `{sortType: sortVariantLocations}`.
 *
 * To export, use `{getCellExportValue: variantLocationExportValue}`.
 *
 * Sample column:
 * ```
 * {
 *   id: 'variant',
 *   Header: 'Variant',
 *   accessor: (originalRow) => {
 *     if (originalRow?.properties?.location) {
 *       return originalRow.properties.location;
 *     }
 *   },
 *   Cell: VariantLocationCell,
 *   sortable: true,
 *   sortType: sortVariantLocations,
 *   Filter: TextFilter,
 *   filter: variantLocationFilter,
 *   getCellExportValue: variantLocationExportValue,
 * }
 * ```
 */
export default function VariantLocationCell({value}) {
  if (!value) {
    return '';
  }
  if (value.haplotypes && value.haplotypes.length > 0) {
    return <AccIdCell value={value.haplotypes} />;
  }
  if (value.variant) {
    return <AccIdCell value={value.variant} />;
  }
  if (value.metabolizers1?.length > 0) {
    const parts = [];
    if (value.genes?.length > 0) {
      parts.push(value.genes[0].symbol);
    }
    parts.push(value.metabolizers1.join(' and '));
    return parts.join(' ');
  }
  return value.displayName || '';
}
VariantLocationCell.propTypes = propTypes;

/**
 * `sortType` for VariantLocationCells.
 */
export function sortVariantLocations(rowA, rowB, columnId) {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  if (!a) {
    if (!b) {
      return 0;
    }
    return 1;
  } else if (!b) {
    return -1;
  }

  let aName;
  let bName;
  if (isArray(a)) {
    aName = getFirstNameFromLocation(a?.[0]);
    bName = getFirstNameFromLocation(b?.[0]);
  } else {
    aName = getFirstNameFromLocation(a);
    bName = getFirstNameFromLocation(b);
  }

  if (!aName) {
    if (!bName) {
      return 0;
    }
    return 1;
  } else if (!bName) {
    return -1;
  }
  return aName.localeCompare(bName);
}


function getFirstNameFromLocation(loc) {
  if (loc.haplotypes && loc.haplotypes.length > 0) {
    return loc.haplotypes[0].symbol || loc.haplotypes[0].name;
  } else if (loc.displayName) {
    return loc.displayName;
  }
  return '';
}


/**
 * `filter` for VariantLocationCells.
 * Use with `Filter: TextFilter`.
 */
export function variantLocationFilter(rows, columnIds, filterValue) {
  if (!filterValue) {
    return rows;
  }
  const id = columnIds[0];
  return matchSorter(rows, filterValue, {
    keys: [(row) => {
      const data = row.values[id];
      if (isArray(data)) {
        return map(data, (d) => getNamesFromLocation(d));
      } else {
        return getNamesFromLocation(data);
      }
    }],
    threshold: matchSorter.rankings.CONTAINS,
  });
}


function getNamesFromLocation(loc) {
  if (loc.haplotypes && loc.haplotypes.length > 0) {
    return map(loc.haplotypes, (h) => h.symbol || h.name);
  } else if (loc.displayName) {
    return loc.displayName;
  }
  return '';
}


/**
 * React-table `getCellExportValue` for VariantLocations.
 *
 * @param {object} row
 * @param {object} column
 * @return {string}
 */
export function variantLocationExportValue(row, column) {
  const value = getNamesFromLocation(row.values[column.id]);
  if (isArray(value)) {
    return join(value, '; ');
  }
  return value;
}
