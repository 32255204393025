/* eslint-disable max-len, no-multi-spaces, array-bracket-spacing */
import {map} from 'lodash';

import ClinicalAnnotationsPediatricTab from 'components/ClinicalAnnotation/PediatricTab';
import ClinicalAnnotationsTab from 'components/ClinicalAnnotation/Tab';
import ConnectionsTab from 'components/ConnectionsTab';
import LabelAnnotationTab from 'components/LabelAnnotation/Tab';
import LinksTab from 'components/LinksTab';
import LiteratureTab from 'components/Literature/Tab';
import PathwaysTab from 'components/Pathway/Tab';
import PrescribingInfoTab from 'components/PrescribingInfo/Tab';
import VariantAnnotationsPediatricTab from 'components/VariantAnnotation/PediatricTab';
import VariantAnnotationsTab from 'components/VariantAnnotation/Tab';
import {GeneAlleleType} from 'conf/enums';


const tabsByType = {
  allele:      ['overview',                                                                                               'literature',            'related', 'link'],
  chemical:    ['overview', 'prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'variantAnnotation',              'literature', 'pathway', 'related', 'link'],
  disease:     ['overview',                                       'clinicalAnnotation', 'variantAnnotation',              'literature', 'pathway', 'related', 'link'],
  gene:        ['overview', 'prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'variantAnnotation', 'haplotype', 'literature', 'pathway', 'related', 'link'],
  variant:     ['overview', 'prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'variantAnnotation', 'haplotype', 'literature',            'related', 'link'],
  haplotype:   ['overview', 'prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'variantAnnotation',              'literature',            'related', 'link'],
  literature:  ['overview', 'prescribingInfo', 'labelAnnotation',                       'variantAnnotation',                            'pathway'                   ],
  combination: ['overview', 'prescribingInfo', 'labelAnnotation', 'clinicalAnnotation', 'variantAnnotation',              'literature', 'pathway'                   ],
};

const tabCountMap = {
  clinicalAnnotation: 'ca',
  prescribingInfo: 'pi',
  haplotype: 'hap',
  labelAnnotation: 'dl',
  literature: 'pubs',
  pathway: 'pw',
  related: 'related',
  variantAnnotation: 'va',
};

export const tabLabels = {
  clinicalAnnotation: 'Clinical Annotations',
  prescribingInfo: 'Prescribing Info',
  haplotype: 'Named Alleles',
  labelAnnotation: 'Drug Label Annotations',
  link: 'Links',
  literature: 'Literature',
  overview: 'Overview',
  pathway: 'Pathways',
  related: 'Related To',
  variantAnnotation: 'Variant Annotations',
};

const tabComponent = {
  clinicalAnnotation: ClinicalAnnotationsTab,
  labelAnnotation: LabelAnnotationTab,
  link: LinksTab,
  literature: LiteratureTab,
  pathway: PathwaysTab,
  prescribingInfo: PrescribingInfoTab,
  related: ConnectionsTab,
  variantAnnotation: VariantAnnotationsTab,
};

const tabComponentPediatric = {
  clinicalAnnotation: ClinicalAnnotationsPediatricTab,
  variantAnnotation: VariantAnnotationsPediatricTab,
};

export function getTabComponent(section, isPediatric) {
  if (isPediatric) {
    return tabComponentPediatric[section] ? tabComponentPediatric[section] : tabComponent[section];
  }
  return tabComponent[section];
}


export function getMenuItems(type, baseUrl, counts, alleleType) {
  const tabs = tabsByType[type];
  if (!tabs) {
    return [];
  }
  return map(tabs, (tab) => {
    let label;
    if (alleleType && tab === 'haplotype') {
      if (alleleType === GeneAlleleType.shortName.namedVariants) {
        label = GeneAlleleType.shortName.namedVariants;
      } else {
        label = GeneAlleleType.shortName.namedAlleles;
      }
    } else {
      label = tabLabels[tab];
    }
    const base = {
      label,
      url: `${baseUrl}/${tab}`,
    };
    if (tab === 'overview') {
      base.otherUrls = [baseUrl];
    }
    if (tabCountMap?.[tab]) {
      const countKey = tabCountMap[tab];
      base.count = counts[countKey] || {total: 0, pediatric: 0};
    }
    return base;
  });
}
