import {filter, map, size} from 'lodash';

import Link from 'components/links/Link';
import Fact from 'components/shared/fact';
import FactSection from 'components/shared/fact_section';
import {LinkOutResource} from 'conf/enums';

export default function AlleleOverviewTab({allele}) {
  const dbsnpLinks = filter(allele.linkOuts, (l) => l.resource === LinkOutResource.shortName.dbSnp);
  const clingenLinks = filter(allele.linkOuts, (l) => l.resource === LinkOutResource.shortName.clinGenAllele);

  return (
    <div className="overviewTab">
      <FactSection halfWidth={true}>
        <Fact label="dbSNP">
          {map(dbsnpLinks, (l) => <Link key={l.id} href={l._url}>{l.resourceId}</Link>)}
        </Fact>
        <Fact label="ClinGen Allele">
          {map(clingenLinks, (l) => <Link key={l.id} href={l._url}>{l.resourceId}</Link>)}
        </Fact>
      </FactSection>

      <FactSection title="Definitions">
        {allele.definitions.map((def, i) => (
          <Fact key={def.id} label={def.assembly || 'Gene'}>{def.name}</Fact>
        ))}
      </FactSection>
    </div>
  );
}
